import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { TableHeaderCellStyle } from '../styles';
import StyledTableCell from './StyledTableCell';

function SubmissionInfoTableHeaderRow() {
	return (
		<TableRow>
			<StyledTableCell style={TableHeaderCellStyle} align='center'>
				#
			</StyledTableCell>
			<StyledTableCell style={TableHeaderCellStyle}>Full Name</StyledTableCell>
			<StyledTableCell style={TableHeaderCellStyle}>Problem</StyledTableCell>
			<StyledTableCell style={TableHeaderCellStyle}>Time</StyledTableCell>
			<StyledTableCell style={TableHeaderCellStyle}>Language</StyledTableCell>
			<StyledTableCell style={TableHeaderCellStyle}>CPU</StyledTableCell>
			<StyledTableCell style={TableHeaderCellStyle}>Memory</StyledTableCell>
			<StyledTableCell style={TableHeaderCellStyle}>Verdict</StyledTableCell>
		</TableRow>
	);
}

export default SubmissionInfoTableHeaderRow;
