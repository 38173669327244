import React from 'react';
import { Tooltip, Chip, Grid } from '@mui/material';
import { SubmissionType } from '../../../../core/submissions/types';
import { TextOverflowStyles } from '../../../contests/ContestSubmissions/SubmissionDetailView/styles';
import { Link, NavLink, useParams } from 'react-router-dom';

export default function AuthorCell({
	fullName,
	username,
	submission_type,
}: {
	fullName: string;
	username: string;
	submission_type: SubmissionType;
}) {
	const { contestSlug } = useParams();
	return (
		<div style={{ display: 'inline-flex', alignItems: 'center', width: '100%', justifyContent: 'start', gap: '4px' }}>
			<Tooltip
				title={
					<Grid container direction={'column'}>
						<Grid item>Username: {username}</Grid>
						<Grid item>Name: {fullName}</Grid>
					</Grid>
				}
			>
				<div
					style={{ ...TextOverflowStyles, cursor: 'pointer' }}
					onClick={() => (window.location.href = `/contests/${contestSlug}/submissions?submitted_by=${username}`)}
				>
					{fullName || '<No name>'}
				</div>
			</Tooltip>
			<div>{submission_type !== 'CONTEST' ? <Chip label='Judge' color='info' size='small' /> : ''}</div>
		</div>
	);
}
