import React, { useEffect, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	TextField,
	Card,
	CardContent,
	Typography,
	Box,
	IconButton,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';
import { Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon, Check, Close } from '@mui/icons-material';
import { Judge, JudgesProps, SearchUser } from '../types';
import {
	addJudgeToAContest,
	getJudgesOfAContest,
	removeJudgeFromContest,
	searchUsers,
} from '../../../core/contests/services';
import { enqueueSnackbar } from 'notistack';
import _ from 'lodash';

function Judges({ contestId }: JudgesProps) {
	const [judges, setJudges] = useState<Judge[]>([]);
	const [showSearch, setShowSearch] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchResults, setSearchResults] = useState<SearchUser[]>([]);
	const [confirmRemoveId, setConfirmRemoveId] = useState<number | null>(null);

	useEffect(() => {
		getJudgesOfAContest(contestId)
			.then((response) => {
				setJudges(response.results);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [contestId]);

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value;
		setSearchQuery(query);

		if (query) {
			searchUsers(query)
				.then((response) => {
					setSearchResults(response.results);
				})
				.catch((error) => {
					console.error('Error fetching users:', error);
				});
		} else {
			setSearchResults([]);
		}
	};

	const handleAddJudge = (user: SearchUser) => {
		addJudgeToAContest(contestId, user.id)
			.then(() => {
				const newJudge: Judge = {
					id: user.id,
					first_name: '',
					last_name: '',
					email: user.email,
					username: user.username,
					is_superuser: false,
				};
				setJudges([...judges, newJudge]);
				setSearchQuery('');
				setSearchResults([]);
				enqueueSnackbar('User has been added as a judge successfully!', {
					variant: 'success',
					autoHideDuration: 3000,
				});
			})
			.catch((error) => {
				console.log(error);
				const errorMessages = error.response.data;
				_.forEach(Object.keys(errorMessages), (key) => {
					enqueueSnackbar(errorMessages[key][0], {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
			});
	};

	const handleConfirmRemove = (id: number) => {
		setConfirmRemoveId(id);
	};

	const handleRemoveJudge = () => {
		if (!confirmRemoveId) return;

		removeJudgeFromContest(contestId, confirmRemoveId)
			.then(() => {
				setJudges(judges.filter((judge) => judge.id !== confirmRemoveId));
				setConfirmRemoveId(null);
				enqueueSnackbar('Judge removed successfully.', {
					variant: 'success',
					autoHideDuration: 3000,
				});
			})
			.catch((error) => {
				console.error('Error removing judge:', error);
				enqueueSnackbar('Failed to remove judge.', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			});
	};

	const handleCancelRemove = () => {
		setConfirmRemoveId(null);
	};

	return (
		<Box sx={{ width: '100%', padding: 2 }}>
			<Card elevation={3} sx={{ width: '100%' }}>
				<CardContent sx={{ padding: 0 }}>
					<Typography variant='h5' gutterBottom sx={{ padding: 2 }}>
						Judges List
					</Typography>

					{/* Judges Table */}
					<TableContainer component={Paper} elevation={2} sx={{ width: '100%', overflowX: 'auto' }}>
						<Table sx={{ width: '100%', tableLayout: 'fixed' }}>
							<TableHead>
								<TableRow>
									<TableCell>
										<strong>Name</strong>
									</TableCell>
									<TableCell>
										<strong>Username</strong>
									</TableCell>
									<TableCell>
										<strong>Email</strong>
									</TableCell>
									<TableCell align='center'>
										<strong>Actions</strong>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{judges.map((judge) => (
									<TableRow key={judge.id} hover>
										<TableCell>{`${judge.first_name || ''} ${judge.last_name || ''}`}</TableCell>
										<TableCell>{judge.username}</TableCell>
										<TableCell sx={{ overflowX: 'auto' }}>{judge.email}</TableCell>
										<TableCell align='center'>
											{confirmRemoveId === judge.id ? (
												<>
													<IconButton color='success' onClick={handleRemoveJudge}>
														<Check />
													</IconButton>
													<Button color='error' onClick={handleCancelRemove}>
														<Close />
													</Button>
												</>
											) : (
												<IconButton color='error' onClick={() => handleConfirmRemove(judge.id)}>
													<DeleteIcon />
												</IconButton>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					{/* Add Judge Button */}
					<Box textAlign='right' marginTop={2} paddingRight={2}>
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							onClick={() => setShowSearch(!showSearch)}
						>
							{showSearch ? 'Cancel Search' : 'Add Judge'}
						</Button>
					</Box>

					{/* Search Bar and Results */}
					{showSearch && (
						<Box marginTop={2} padding={2}>
							<TextField
								label='Search for Judges'
								value={searchQuery}
								onChange={handleSearch}
								fullWidth
								InputProps={{
									startAdornment: <SearchIcon />,
								}}
							/>

							{/* Search Results List */}
							{searchResults?.length > 0 && (
								<List sx={{ maxHeight: 200, overflowY: 'auto', marginTop: 2 }}>
									{searchResults.map((user) => (
										<ListItem
											key={user.id}
											secondaryAction={
												<IconButton edge='end' onClick={() => handleAddJudge(user)}>
													<AddIcon />
												</IconButton>
											}
										>
											<ListItemText primary={`${user.username}`} secondary={user.email} />
										</ListItem>
									))}
								</List>
							)}
						</Box>
					)}
				</CardContent>
			</Card>
		</Box>
	);
}

export default Judges;
