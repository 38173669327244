import { ProblemStatus } from '../../../core/standings/types';
import { Box } from '@mui/material';
import SolveStatus from './SolveStatus';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { problemCellStyle } from './styles';

function ProblemCell({ problem, submittedBy }: { problem: ProblemStatus; submittedBy: string }) {
	const { contestSlug } = useParams();
	return (
		<Link
			to={`/contests/${contestSlug}/submissions?problem_set=${problem.id}&submitted_by=${submittedBy}`}
			style={{ textDecoration: 'none' }}
		>
			<Box style={problemCellStyle}>
				{problem.total_tries === 0 ? (
					'' // Problem not attempted
				) : (
					<SolveStatus {...problem} />
				)}
			</Box>
		</Link>
	);
}

export default ProblemCell;
