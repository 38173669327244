import React from 'react';
import DatasetForm from './DatasetForm';
import { Dialog, DialogContent } from '@mui/material';
import { Dataset, DatasetSaveBody } from '../../../../core/datasets/types';
import { createNewContestDataset } from '../../../../core/datasets/services';

export default function CreateDatasetModal({
	isOpen,
	onClose,
	problemId,
	onSuccess,
	problemSetId,
}: {
	isOpen: boolean;
	onClose: () => void;
	problemId: number;
	onSuccess: (dataset: Dataset) => void;
	problemSetId: number;
}) {
	const initFormData: Dataset = {
		title: '',
		problem: problemId,
	};

	const handleSubmit = async (data: DatasetSaveBody) => {
		const newDataset = await createNewContestDataset(problemId, problemSetId, data);
		onSuccess(newDataset);
		return newDataset;
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'md'}>
			<DialogContent sx={{ backgroundColor: 'white' }}>
				<DatasetForm
					type='create'
					initFormData={initFormData}
					handleSubmit={handleSubmit}
					onSuccess={() => {
						onClose();
					}}
					onClose={onClose}
				/>
			</DialogContent>
		</Dialog>
	);
}
