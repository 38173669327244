import { ProblemStatus, StandingsItem } from '../../../core/standings/types';
import { Card, TableCell, TableRow } from '@mui/material';
import React from 'react';
import ProblemCell from './ProblemCell';
import { problemHeaderStyle, solveCountStyle, statusCardStyle } from './styles';
import Tooltip from '@mui/material/Tooltip';
import { Link, useParams } from 'react-router-dom';

interface Props {
	offset: number;
	index: number | null | undefined;
	data: StandingsItem;
}

function StandingsRow({ offset, index, data }: Props) {
	const { contestSlug } = useParams();

	return (
		<>
			<TableCell align={'center'}>
				<strong style={{ fontSize: '16px', color: 'rgb(0,70,101)' }}>{offset + (index || 0) + 1}</strong>
			</TableCell>
			<TableCell>
				<Tooltip title={data?.username}>
					<strong style={{ fontSize: '16px', color: 'rgb(0,73,110)' }}>{data?.fullname}</strong>
				</Tooltip>
				<div style={{ fontSize: '12px', color: 'rgb(110,110,110)' }}>{data?.institution?.toUpperCase()}</div>
			</TableCell>
			<TableCell align={'center'} style={problemHeaderStyle}>
				<Link
					to={`/contests/${contestSlug}/submissions?submitted_by=${data.username}`}
					style={{ textDecoration: 'none' }}
				>
					<Card sx={statusCardStyle}>
						<div
							style={solveCountStyle}
							onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#6bbef0')}
							onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#419ad8')}
						>
							{data?.problem_list.filter((problem: ProblemStatus) => problem.is_solved).length}
						</div>
						<div>({data?.problem_list.reduce((acc, problem) => acc + (problem.is_solved ? problem.fine : 0), 0)})</div>
					</Card>
				</Link>
			</TableCell>
			{data?.problem_list.map((problemStatus, index) => (
				<TableCell align={'center'} key={index}>
					<ProblemCell problem={problemStatus} submittedBy={data.username} />
				</TableCell>
			))}
		</>
	);
}

export default StandingsRow;
