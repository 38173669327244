import React from 'react';
import { SectionPaper, SectionTitle } from '../../../../design-library';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import DeleteDatasetModal from './DeleteDatasetModal';
import { Dataset } from '../../../../core/datasets/types';
import moment from 'moment';
import EditDatasetModal from './EditDatasetModal';
import CodeFetchAndShow from '../../../common/CodeFetchAndShow';
import Tooltip from '@mui/material/Tooltip';

export default function DatasetListItem({
	dataset,
	onDeleteSuccess,
	problemSetId,
}: {
	dataset: Dataset;
	onDeleteSuccess: (datasetId: number) => void;
	problemSetId: number;
}) {
	const [datasetViewData, setDatasetViewData] = React.useState<Dataset>(dataset);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
	const [isEditDialogOpen, setIsEditDialogOpen] = React.useState<boolean>(false);

	const handleEditIconClick = () => {
		setIsEditDialogOpen(true);
	};

	const handleDeleteIconClick = () => {
		setIsDeleteDialogOpen(true);
	};

	return (
		<Grid direction='row' container sx={{ width: '100%' }}>
			<SectionTitle
				title={`[${datasetViewData.test_case_order}] ${datasetViewData.title}`}
				size='small'
				isDeletable={true}
				onDelete={handleDeleteIconClick}
				isEditable={true}
				onEdit={handleEditIconClick}
			/>
			<SectionPaper>
				<Grid item container direction='column' spacing={2} sx={{ padding: '10px' }}>
					<Grid item container direction={'row'} spacing={2}>
						<Grid item>
							<ShowUrlData url={datasetViewData.input_url!} type='input' />
						</Grid>
						<Grid item>
							<ShowUrlData url={datasetViewData.output_url!} type='output' />
						</Grid>
					</Grid>
					<Grid item xs={12} container justifyContent={'start'} spacing={2}>
						<Grid item>
							<Tooltip title={moment(datasetViewData!.created_at).format('llll')}>
								<Typography variant={'subtitle2'}>
									Created at: {moment(datasetViewData!.created_at).fromNow().toString()}
								</Typography>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title={moment(datasetViewData!.updated_at).format('llll')}>
								<Typography variant={'subtitle2'}>
									Updated at: {moment(datasetViewData!.updated_at).fromNow().toString()}
								</Typography>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</SectionPaper>
			<EditDatasetModal
				isOpen={isEditDialogOpen}
				onClose={() => setIsEditDialogOpen(false)}
				dataset={datasetViewData}
				setDataset={setDatasetViewData}
				problemSetId={problemSetId}
			/>
			<DeleteDatasetModal
				datasetId={dataset.id!}
				title={dataset.title ?? ''}
				isOpen={isDeleteDialogOpen}
				onClose={() => setIsDeleteDialogOpen(false)}
				onDeleteSuccess={onDeleteSuccess}
				problemSetId={problemSetId}
			/>
		</Grid>
	);
}

const ShowUrlData = ({ url, type }: { url: string; type: 'input' | 'output' }) => {
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	return (
		<div>
			<Button variant={'outlined'} color={'primary'} onClick={() => setIsModalOpen(true)}>
				View {type} data
			</Button>
			<Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} fullWidth={true} maxWidth={'md'}>
				<DialogContent>
					<Typography>
						<a href={url} rel='noreferrer' target='_blank' download>
							Download {type} file
						</a>
					</Typography>
					<CodeFetchAndShow url={url} language={'text'} />
				</DialogContent>
			</Dialog>
		</div>
	);
};
