import React, { useEffect, useState } from 'react';
import serverApi from '../../../core/serverApi';
import { Grid } from '@mui/material';
import { BulkUserCreationData } from '../../../core/contestants/types';
import { getBulkUserCreationData } from '../../../core/contestants/services';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadedFilesTable from './UploadedFilesTable';
import { SectionPaper } from '../../../design-library/Section/SectionPaper';

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export default function CsvFileUploader({ contest_id }: { contest_id: number }) {
	const [file, setFile] = useState<null | File>(null);
	const [latestFileId, setLatestFileId] = useState(null);
	const [bulkUserData, setBulkUserData] = useState<null | BulkUserCreationData>(null);

	useEffect(() => {
		getBulkUserCreationData(contest_id)
			.then((bulkUserCreationData: BulkUserCreationData) => {
				setBulkUserData(bulkUserCreationData);
			})
			.catch((e) => {
				if (axios.isAxiosError(e)) {
					enqueueSnackbar(`Something went wrong fetching the files!`, { variant: 'error' });
				}
			});
	}, [latestFileId]);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			setFile(e.target.files[0]);
		} else {
			setFile(null);
		}
	};

	const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		if (!file) {
			alert('Please select a file first.');
			return;
		}

		try {
			const res = await serverApi.post(
				'/api/judge/contest-bulk-user-generation/',
				{ file: file, contest: contest_id },
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			enqueueSnackbar('File Uploaded Successfully! Please Reload page for validation status', { variant: 'success' });

			setLatestFileId(res.data.id);
		} catch (error) {
			enqueueSnackbar('Something went wrong! Please reload page and try again!', { variant: 'error' });
			console.error('There was a problem with the fetch operation:', error);
		}
	};

	return (
		<Grid item xs={12}>
			<SectionPaper>
				<Grid
					container
					direction='column'
					justifyContent='center'
					sx={{ width: '100%', padding: '35px', gap: 2 }}
					component={'form'}
				>
					<Grid item>User Creation</Grid>
					<Grid item container>
						<Grid item>Uploaded files: {bulkUserData?.count ?? 0}</Grid>
						<Grid item>
							<UploadedFilesTable files={bulkUserData?.results ?? []} />
						</Grid>
					</Grid>
					<Grid item>
						<Grid>File Name: {file?.name ?? 'No file selected'}</Grid>
						<Button
							component='label'
							role={undefined}
							// variant="contained"
							tabIndex={-1}
							startIcon={<CloudUploadIcon />}
						>
							Select file
							<VisuallyHiddenInput type='file' onChange={handleFileChange} />
						</Button>
						<Button variant='contained' onClick={handleSubmit}>
							Upload this file
						</Button>
					</Grid>
				</Grid>
			</SectionPaper>
		</Grid>
	);
}
