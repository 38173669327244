import React from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { Clarification } from '../../../../../core/clarifications/types';
import MarkdownView from '../../../../../design-library/MarkdownView';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';

export default function ClarificationView({
	isOpen,
	isContestManager,
	setIsOpen,
	clarification,
}: {
	isOpen: boolean;
	isContestManager: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	clarification: Clarification;
}) {
	return (
		<Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth={true} maxWidth={'md'}>
			<DialogContent sx={{ backgroundColor: 'white' }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant={'h5'} color={'primary'}>
							{clarification.clarification_type === 'PROBLEM'
								? `[${clarification.problem_set!.order_character}. ${clarification.problem!.title}] `
								: `[${_.capitalize(clarification.clarification_type)}] `}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant={'h5'} color={'primary'}>
							Question/Title:
						</Typography>
						<MarkdownView data={clarification.question || '<No title set>'} />
					</Grid>
					<Grid item xs={12}>
						<Typography variant={'h5'} color={'primary'}>
							Answer:
						</Typography>
						{clarification.answer ? (
							<MarkdownView data={clarification.answer} />
						) : (
							<Typography>Not answered yet!</Typography>
						)}
					</Grid>
					<Grid item xs={12} container justifyContent={'end'} marginTop={2} spacing={1}>
						<Grid item>
							<Tooltip title={isContestManager ? clarification.created_by!.username : 'JUDGE'}>
								<Typography variant={'subtitle2'}>
									{`Created by: ${
										isContestManager
											? clarification.created_by!.full_name || clarification.created_by!.username
											: 'JUDGE'
									}`}
								</Typography>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title={moment(clarification.created_at).format('llll')}>
								<Typography variant={'subtitle2'}>
									Created at: {moment(clarification.created_at).fromNow().toString()}
								</Typography>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip title={moment(clarification.updated_at).format('llll')}>
								<Typography variant={'subtitle2'}>
									Updated at: {moment(clarification.updated_at).fromNow().toString()}
								</Typography>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
