import styled from '@emotion/styled';
import { TableCell } from '@mui/material';

const StyledRankCell = styled(TableCell)(({ theme }) => ({
	position: 'relative',
	'& .rank-display': {
		display: 'block',
	},
	'& .reload-icon': {
		display: 'none',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	'&:hover .rank-display': {
		display: 'none',
	},
	'&:hover .reload-icon': {
		display: 'block',
	},
}));

export default StyledRankCell;
