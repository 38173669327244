import React from 'react';
import {
	Box,
	FormControlLabel,
	Grid,
	IconButton,
	Pagination,
	Switch,
	Table,
	TableBody,
	TableHead,
	Tooltip,
	Typography,
} from '@mui/material';
import { SectionTitle } from '../../../../../design-library';
import { Submission } from '../../../../../core/submissions/types';
import { LinearLoader } from '../../../../../design-library/LinearLoader';
import CachedIcon from '@mui/icons-material/Cached';
import SubmissionInfoTableHeaderRow from '../../SubmissionDetailView/SubmissionInfo/SubmissionInfoTableHeaderRow';
import SubmissionInfoTableDataRow from '../../SubmissionDetailView/SubmissionInfo/SubmissionInfoTableDataRow';
import { motion } from 'framer-motion';

export default function SubmissionList({
	isLoading,
	contestSlug,
	submissions,
	page,
	totalPages,
	totalResults,
	onPageChangeHandler,
	showingOwnSubmission,
	toggleShowOwnSubmissions,
	onReloadPage,
}: {
	isLoading: boolean;
	contestSlug: string;
	submissions: Submission[];
	page: number;
	totalPages: number;
	totalResults: number;
	onPageChangeHandler: (event: React.ChangeEvent<unknown>, value: number) => Promise<void>;
	showingOwnSubmission: boolean;
	toggleShowOwnSubmissions: () => void;
	onReloadPage: () => void;
}) {
	return (
		<Grid container direction='row'>
			<Grid item xs={12}>
				<SectionTitle
					title={'Submissions'}
					size={'small'}
					actions={
						<Grid container spacing={2} alignItems={'center'} alignContent={'center'}>
							<Grid item>
								<FormControlLabel
									value='My submissions only'
									control={
										<Switch color='primary' checked={showingOwnSubmission} onChange={toggleShowOwnSubmissions} />
									}
									label='My submissions only'
									labelPlacement='start'
									sx={{ color: '#2F5D62' }}
								/>
							</Grid>
							<Grid item>
								<Tooltip title={'Reload list'}>
									<IconButton onClick={onReloadPage}>
										<CachedIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					}
				/>
				{isLoading || !submissions ? (
					<LinearLoader />
				) : (
					<Box sx={{ width: '100%', height: '4px', margin: '8px 0px' }} />
				)}
				{submissions && submissions.length !== 0 ? (
					<Table style={{ margin: '3px auto' }}>
						<TableHead style={{ backgroundColor: '#ffffff' }}>
							<SubmissionInfoTableHeaderRow />
						</TableHead>
						<TableBody>
							{submissions.map((submission) => (
								<motion.tr
									key={submission.id}
									layout
									initial={{ opacity: 0 }}
									animate={{ opacity: submission.submission_type !== 'CONTEST' ? 0.6 : 1 }}
									exit={{ opacity: 0 }}
									transition={{
										type: 'spring',
										stiffness: 300,
										damping: 100,
									}}
									style={{
										opacity: submission.submission_type !== 'CONTEST' ? 0.6 : 1,
										backgroundColor: submission.submission_type !== 'CONTEST' ? '#f2f7ff' : 'white',
										position: 'relative',
									}}
									whileHover={{ backgroundColor: '#f0f0f0' }}
								>
									<SubmissionInfoTableDataRow key={submission.id} submission={submission} />
								</motion.tr>
							))}
						</TableBody>
					</Table>
				) : (
					<Grid item>
						<Typography variant={'h6'} color={'primary'} sx={{ padding: '12px' }}>
							{'No results found!'}
						</Typography>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12} sx={{ padding: '10px' }}>
				<Typography variant={'h6'} color={'primary'}>{`Total results found: ${totalResults}`}</Typography>
			</Grid>
			<Grid
				item
				container
				xs={12}
				direction='column'
				justifyContent='center'
				alignItems='center'
				sx={{ marginTop: '20px' }}
			>
				<Pagination
					page={page}
					count={totalPages}
					variant='outlined'
					shape='rounded'
					size='large'
					onChange={onPageChangeHandler}
					boundaryCount={1}
				/>
			</Grid>
		</Grid>
	);
}
