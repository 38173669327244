import { Link, TableCell } from '@mui/material';
import React from 'react';
import VerdictCell from '../Commons/VerdictCell';
import AuthorCell from '../../../../common/tableCellRenderers/itemComponents/AuthorCell';
import ProblemTitleCell from '../../../../common/tableCellRenderers/itemComponents/ProblemTitleCell';
import { NavLink, useParams } from 'react-router-dom';
import SubmissionTimeCell from '../../../../common/tableCellRenderers/itemComponents/SubmissionTimeCell';
import CPUCell from '../../../../common/tableCellRenderers/itemComponents/CPUCell';
import MemoryCell from '../../../../common/tableCellRenderers/itemComponents/MemoryCell';
import { Submission } from '../../../../../core/submissions/types';
import StyledTableCell from './StyledTableCell';

function SubmissionInfoTableDataRow({ submission }: { submission: Submission }) {
	const { contestSlug } = useParams();

	return (
		<>
			<StyledTableCell align='center'>
				<Link to={`/contests/${contestSlug}/submissions/${submission.id}`} component={NavLink}>
					{submission.id}
				</Link>
			</StyledTableCell>
			<StyledTableCell>
				<AuthorCell
					fullName={submission.submitted_by.full_name}
					username={submission.submitted_by.username}
					submission_type={submission.submission_type}
				/>
			</StyledTableCell>
			<StyledTableCell>
				<ProblemTitleCell
					contestSlug={contestSlug!}
					orderCharacter={submission.problem_set.order_character}
					problemTitle={submission.problem.title}
				/>
			</StyledTableCell>
			<StyledTableCell>
				<SubmissionTimeCell submissionTime={submission.created_at} />
			</StyledTableCell>
			<StyledTableCell>{submission.language}</StyledTableCell>
			<StyledTableCell>
				<CPUCell cpu={submission.cpu} />
			</StyledTableCell>
			<StyledTableCell>
				<MemoryCell memory={submission.memory} />
			</StyledTableCell>
			<StyledTableCell>
				<VerdictCell status={submission.status} verdict={submission.response} />
			</StyledTableCell>
		</>
	);
}

export default SubmissionInfoTableDataRow;
