import React, { useState } from 'react';
import { Button, CircularProgress, Grid, TextField, InputAdornment, IconButton } from '@mui/material';
import { SectionPaper, SectionTitle } from '../../design-library';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { forEach } from 'lodash';
import { useTitle } from '../../contexts/TitleContext';
import { updatePassword } from '../../core/user/services';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function UpdatePassword() {
	const { setTitle } = useTitle();
	setTitle('Update Password');
	const { enqueueSnackbar } = useSnackbar();
	const [newPassword, setNewPassword] = useState('');
	const [reNewPassword, setReNewPassword] = useState('');
	const [currentPassword, setCurrentPassword] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
	const [showRepeatNewPassword, setShowRepeatNewPassword] = useState<boolean>(false);

	const onSubmitHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		setIsLoading(true);
		console.log('update password');
		updatePassword(currentPassword, newPassword, reNewPassword)
			.then((_) => {
				enqueueSnackbar('Password updated successfully!', {
					variant: 'success',
					autoHideDuration: 3000,
				});
				setIsLoading(false);
			})
			.catch((error) => {
				if (axios.isAxiosError(error)) {
					console.log(error);
					const errorFields: string[] = error.response?.data;
					forEach(errorFields, (err) => {
						console.log(err);
						enqueueSnackbar(err[0], {
							variant: 'error',
							autoHideDuration: 4000,
						});
					});
				} else {
					enqueueSnackbar(`Something went wrong!`, { variant: 'error' });
				}
				setIsLoading(false);
			});
	};

	return (
		<Grid container alignItems='center' justifyContent='center'>
			<Grid item container direction='row' xs={4} sx={{ marginY: '100px', minWidth: '450px' }} rowSpacing={'2px'}>
				<Grid item xs={12}>
					<SectionTitle title='Login' size='small' />
				</Grid>
				<Grid item xs={12}>
					<SectionPaper>
						<Grid
							container
							direction='column'
							justifyContent='center'
							sx={{ width: '100%', padding: '35px', gap: 2 }}
							component={'form'}
						>
							<TextField
								autoFocus
								id='outlined-basic'
								label='Current Password'
								variant='outlined'
								value={currentPassword}
								onChange={(event) => setCurrentPassword(event.target.value)}
								type={showCurrentPassword ? 'text' : 'password'}
								slotProps={{
									input: {
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={() => setShowCurrentPassword((prevState: boolean) => !prevState)}
													edge='end'
												>
													{showCurrentPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									},
								}}
							/>
							<TextField
								id='outlined-basic'
								label='New Password'
								variant='outlined'
								value={newPassword}
								onChange={(event) => setNewPassword(event.target.value)}
								type={showNewPassword ? 'text' : 'password'}
								slotProps={{
									input: {
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton onClick={() => setShowNewPassword((prevState: boolean) => !prevState)} edge='end'>
													{showNewPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									},
								}}
							/>
							<TextField
								id='outlined-basic'
								label='Retype New Password'
								variant='outlined'
								value={reNewPassword}
								onChange={(event) => setReNewPassword(event.target.value)}
								type={showRepeatNewPassword ? 'text' : 'password'}
								slotProps={{
									input: {
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={() => setShowRepeatNewPassword((prevState: boolean) => !prevState)}
													edge='end'
												>
													{showRepeatNewPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									},
								}}
							/>
							<Button disabled={isLoading} variant='contained' onClick={onSubmitHandler} size='large' type={'submit'}>
								{isLoading ? <CircularProgress size='26px' color='inherit' /> : 'Update Password'}
							</Button>
						</Grid>
					</SectionPaper>
				</Grid>
			</Grid>
		</Grid>
	);
}
