import React, { useEffect } from 'react';
import { Problem } from '../../../../core/problems/types';
import { Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DatasetListItem from './DatasetListItem';
import CreateDatasetModal from './CreateDatasetModal';
import { Dataset } from '../../../../core/datasets/types';
import { getContestDatasets } from '../../../../core/datasets/services';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { LinearLoader } from '../../../../design-library/LinearLoader';

export default function ProblemDataset({ problem }: { problem: Problem }) {
	const [isDatasetCreateModalOpen, setIsDatasetCreateModalOpen] = React.useState<boolean>(false);
	const [datasets, setDatasets] = React.useState<Dataset[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		getContestDatasets(problem.id!, problem.problem_set!.id)
			.then((datasets: Dataset[]) => {
				setDatasets(datasets);
			})
			.catch((e) => {
				if (axios.isAxiosError(e)) {
					enqueueSnackbar(`Something went wrong fetching the datasets!`, { variant: 'error' });
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const handleDatasetDeleteSuccess = (datasetId: number) => {
		setDatasets(datasets.filter((dataset) => dataset.id !== datasetId));
	};

	const handleDatasetCreateSuccess = (dataset: Dataset) => {
		setDatasets([...datasets, dataset]);
	};

	if (isLoading) return <LinearLoader />;

	return (
		<Grid container direction='column' justifyContent='center' sx={{ width: '100%', gap: 2 }}>
			<Grid item container direction='row' spacing={2}>
				{datasets.map((dataset) => (
					<Grid item key={dataset.id!} xs={12}>
						<DatasetListItem
							dataset={dataset}
							onDeleteSuccess={handleDatasetDeleteSuccess}
							problemSetId={problem.problem_set!.id}
						/>
					</Grid>
				))}
			</Grid>
			<Grid item>
				<Button startIcon={<AddIcon />} variant='contained' onClick={() => setIsDatasetCreateModalOpen(true)}>
					Add New Dataset
				</Button>
			</Grid>
			<CreateDatasetModal
				isOpen={isDatasetCreateModalOpen}
				onClose={() => setIsDatasetCreateModalOpen(false)}
				problemId={problem.id!}
				onSuccess={handleDatasetCreateSuccess}
				problemSetId={problem.problem_set!.id}
			/>
		</Grid>
	);
}
