import React, { useState } from 'react';
import { Grid, TextField, Button, CircularProgress, FormHelperText } from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { SectionPaper, SectionTitle } from '../../../../design-library';
import { Dataset, DatasetSaveBody } from '../../../../core/datasets/types';
import _ from 'lodash';

export default function DatasetForm({
	type,
	initFormData,
	handleSubmit,
	onSuccess,
	onClose,
}: {
	type: 'create' | 'edit';
	initFormData: Dataset;
	handleSubmit: (data: DatasetSaveBody) => Promise<Dataset>;
	onSuccess: () => void;
	onClose: () => void;
}) {
	const { enqueueSnackbar } = useSnackbar();
	const [formData, setFormData] = useState<DatasetSaveBody & Dataset>(initFormData);
	const [errorData, setErrorData] = useState<{ [key in keyof DatasetSaveBody]?: string[] }>({});
	const [isLoading, setIsLoading] = useState(false);
	const [fileNames, setFileNames] = useState<{ input_file_name: string | null; output_file_name: string | null }>({
		input_file_name: null,
		output_file_name: null,
	});

	const handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, files } = event.target;
		if (files) {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: files[0] ?? undefined,
			}));
			setFileNames((prevFileNames) => ({
				...prevFileNames,
				[`${name}_name`]: files[0]?.name ?? null,
			}));
		}
	};

	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const onSubmitHandler = async () => {
		try {
			setIsLoading(true);
			const { input_file, output_file, ...restFormData } = formData;
			const dataToSubmit: DatasetSaveBody = {
				...restFormData,
				...(input_file ? { input_file } : {}),
				...(output_file ? { output_file } : {}),
			};
			const response = await handleSubmit(dataToSubmit);
			enqueueSnackbar(`Dataset "${response.title}" ${type === 'create' ? 'created' : 'edited'} successfully!`, {
				variant: 'success',
			});
			onSuccess();
		} catch (e) {
			if (axios.isAxiosError(e)) {
				setErrorData(e.response?.data ?? {});
				enqueueSnackbar(`Dataset could not be ${type === 'create' ? 'created' : 'edited'}!`, { variant: 'error' });
			} else {
				enqueueSnackbar(`Something went wrong!`, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Grid container alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
			<Grid item container direction='row' xs={12} rowSpacing={'2px'}>
				<Grid item xs={12}>
					<SectionTitle title={`${type === 'create' ? 'Create new' : 'Edit'} Dataset`} size='small' />
				</Grid>
				<Grid item xs={12}>
					<SectionPaper>
						<Grid container sx={{ padding: '35px', gap: 2 }} component={'form'}>
							<Grid container item spacing={2}>
								{type !== 'create' && (
									<Grid item xs={2}>
										<TextField
											fullWidth
											id='test-case-order'
											type='number'
											name='test_case_order'
											label='Order'
											variant='outlined'
											value={formData.test_case_order}
											onChange={handleValueChange}
											error={!!errorData.test_case_order}
											helperText={errorData.test_case_order}
										/>
									</Grid>
								)}
								<Grid item xs>
									<TextField
										fullWidth
										id='itle'
										name='title'
										label='Title'
										variant='outlined'
										value={formData.title}
										onChange={handleValueChange}
										error={!!errorData.title}
										helperText={errorData.title}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Button
									fullWidth
									variant={'outlined'}
									component='label'
									color={fileNames.input_file_name ? 'info' : 'primary'}
								>
									{fileNames.input_file_name ?? (type === 'create' ? 'Select Input File *' : 'Update Input File')}
									<input type='file' name='input_file' hidden onChange={handleSelectedFile} />
								</Button>
								<FormHelperText error={!!errorData.input_file}>{errorData.input_file}</FormHelperText>
							</Grid>
							<Grid item xs={12}>
								<Button
									fullWidth
									variant={'outlined'}
									component='label'
									color={fileNames.output_file_name ? 'info' : 'primary'}
								>
									{fileNames.output_file_name ?? (type === 'create' ? 'Select Output File *' : 'Update Output File')}
									<input type='file' name='output_file' hidden onChange={handleSelectedFile} />
								</Button>
								<FormHelperText error={!!errorData.output_file}>{errorData.output_file}</FormHelperText>
							</Grid>
							<Grid container spacing={2} justifyContent={'end'}>
								<Grid item>
									<Button variant='outlined' onClick={onClose} size='large' fullWidth>
										Cancel
									</Button>
								</Grid>
								<Grid item>
									<Button disabled={isLoading} variant='contained' onClick={onSubmitHandler} size='large' fullWidth>
										{isLoading ? <CircularProgress size={25} /> : `${type === 'create' ? 'Create' : 'Edit'} Dataset`}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</SectionPaper>
				</Grid>
			</Grid>
		</Grid>
	);
}
