import React from 'react';
import { TableStyle } from '../styles';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Submission } from '../../../../../core/submissions/types';
import { TableHeaderStyle } from '../styles';
import SubmissionInfoTableDataRow from './SubmissionInfoTableDataRow';
import SubmissionInfoTableHeaderRow from './SubmissionInfoTableHeaderRow';

function SubmissionInfoTable({ submission }: { submission: Submission }) {
	return (
		<Table style={TableStyle}>
			<TableHead style={TableHeaderStyle}>
				<SubmissionInfoTableHeaderRow />
			</TableHead>
			<TableBody>
				<TableRow>
					<SubmissionInfoTableDataRow submission={submission} />
				</TableRow>
			</TableBody>
		</Table>
	);
}

export default SubmissionInfoTable;
