import { ProblemStatus, StandingsItem } from '../../../core/standings/types';
import { Card, IconButton, TableCell } from '@mui/material';
import React, { useEffect } from 'react';
import ProblemCell from './ProblemCell';
import { problemHeaderStyle, solveCountStyle, statusCardStyle } from './styles';
import Tooltip from '@mui/material/Tooltip';
import { motion } from 'framer-motion';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import StyledRankCell from './StyledRankCell';
import { Link, useParams } from 'react-router-dom';

interface Props {
	rank: number;
	data: StandingsItem;
	refProp?: React.RefObject<HTMLTableRowElement>;
	onPublishRankClick: () => void;
	motionProps: any;
}

function StandingsRow({ rank, data, refProp, onPublishRankClick, motionProps = {} }: Props) {
	const [rankNow, setRankNow] = React.useState<number>(rank);

	useEffect(() => {
		if (rank !== null) {
			setRankNow(rank);
		}
	}, [rank]);

	const { contestSlug } = useParams();

	return (
		<motion.tr ref={refProp} {...motionProps}>
			<>
				<StyledRankCell align='center'>
					<strong className='rank-display'>{rank}</strong>
					<Tooltip title='Sync final rank' arrow>
						<IconButton className='reload-icon' onClick={onPublishRankClick}>
							<PublishedWithChangesIcon color='secondary' />
						</IconButton>
					</Tooltip>
				</StyledRankCell>
				<TableCell>
					<Tooltip title={data?.username}>
						<strong style={{ fontSize: '16px', color: 'rgb(0,73,110)' }}>{data?.fullname}</strong>
					</Tooltip>
					<div style={{ fontSize: '12px', color: 'rgb(110,110,110)' }}>{data?.institution?.toUpperCase()}</div>
				</TableCell>
				<TableCell align={'center'} style={problemHeaderStyle}>
					<Link
						to={`/contests/${contestSlug}/submissions?submitted_by=${data.username}`}
						style={{ textDecoration: 'none' }}
					>
						<Card sx={statusCardStyle}>
							<div
								style={solveCountStyle}
								onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#6bbef0')}
								onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#419ad8')}
							>
								{data?.problem_list.filter((problem: ProblemStatus) => problem.is_solved).length}
							</div>
							<div>
								({data?.problem_list.reduce((acc, problem) => acc + (problem.is_solved ? problem.fine : 0), 0)})
							</div>
						</Card>
					</Link>
				</TableCell>
				{data?.problem_list.map((problemStatus, index) => (
					<TableCell align={'center'} key={index}>
						<ProblemCell problem={problemStatus} submittedBy={data.username} />
					</TableCell>
				))}
			</>
		</motion.tr>
	);
}

export default StandingsRow;
