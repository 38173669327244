import { ProblemStatus } from '../../../core/standings/types';
import { Card } from '@mui/material';
import React from 'react';
import { solvedStatusStyle, statusCardStyle, unsolvedStatusStyle } from './styles';

function SolveStatus(problem: ProblemStatus) {
	const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>, type: 'unsolved' | 'solved' | 'first_solve') => {
		if (type === 'unsolved') {
			e.currentTarget.style.backgroundColor = '#f77a7a';
			return;
		} else if (type === 'first_solve') {
			e.currentTarget.style.backgroundImage = 'linear-gradient(to right, #cac531, #e0e07a)';
			return;
		}
		e.currentTarget.style.backgroundColor = '#66e096';
	};

	const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>, type: 'unsolved' | 'solved' | 'first_solve') => {
		if (type === 'unsolved') {
			e.currentTarget.style.backgroundColor = '#f23030';
			return;
		} else if (type === 'first_solve') {
			e.currentTarget.style.backgroundImage = 'linear-gradient(to right, #b8a14f, #d4c76e)';
			return;
		}
		e.currentTarget.style.backgroundColor = '#33bd7a';
	};

	const shinyGoldenStyle: React.CSSProperties = {
		...solvedStatusStyle,
		backgroundImage: 'linear-gradient(to right, #b8a14f, #d4c76e)',
		color: '#FFFFFF',
		position: 'relative',
		overflow: 'hidden',
	};

	const shineStyle: React.CSSProperties = {
		content: '""',
		position: 'absolute',
		top: 0,
		left: '-120%',
		width: '200%',
		height: '100%',
		background:
			'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%)',
		animation: 'shine 2s linear infinite',
	};

	// Global CSS for the shine animation
	const shineKeyframes = `
        @keyframes shine {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(100%);
            }
        }
    `;
	const styleSheet = document.styleSheets[0];
	if (styleSheet && !Array.from(styleSheet.cssRules).some((rule) => rule.cssText.includes('@keyframes shine'))) {
		styleSheet.insertRule(shineKeyframes, styleSheet.cssRules.length);
	}

	return (
		<Card style={statusCardStyle}>
			<div>
				{problem.is_solved ? (
					<div
						style={problem.is_first_solve ? shinyGoldenStyle : solvedStatusStyle}
						onMouseEnter={(e) => handleMouseEnter(e, problem.is_first_solve ? 'first_solve' : 'solved')}
						onMouseLeave={(e) => handleMouseLeave(e, problem.is_first_solve ? 'first_solve' : 'solved')}
					>
						{problem.is_first_solve && <div style={shineStyle}></div>}
						{problem.is_first_solve ? <>&#9733;</> : <>&#10003;</>} {/* Checkmark for solved */}
					</div>
				) : problem.total_tries > 0 ? (
					<div
						style={unsolvedStatusStyle}
						onMouseEnter={(e) => handleMouseEnter(e, 'unsolved')}
						onMouseLeave={(e) => handleMouseLeave(e, 'unsolved')}
					></div>
				) : (
					<div></div>
				)}
			</div>
			<div>
				{problem.total_tries}
				{problem.is_solved && <span>({problem.fine})</span>}
			</div>
		</Card>
	);
}

export default SolveStatus;
