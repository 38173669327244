import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import ContestHeading from '../../common/ContestHeading';
import moment from 'moment';
import { useTitle } from '../../../contexts/TitleContext';
import { useParams } from 'react-router-dom';
import { ContestRead } from '../../../core/contests/types';
import { StandingsItem, ProblemAttempt } from '../../../core/standings/types';
import { getContestData } from '../../../core/contests/services';
import { getInternalStandings, getPublicStandings } from '../../../core/standings/services';
import { enqueueSnackbar } from 'notistack';
import { LinearLoader } from '../../../design-library/LinearLoader';
import { problemHeaderStyle } from './styles';
import AnnounceStandingsRow from './AnnounceStandingsRow';
import StyledRankCell from './StyledRankCell';
import RestoreIcon from '@mui/icons-material/Restore';
import { CONTESTS_NAMESPACE } from '../../../core/store/constants';
import { Link } from 'react-router-dom';

const ContestStandingsAnnouncement: React.FC = () => {
	const { setTitle } = useTitle();
	setTitle('Standings Announcement');
	const { contestSlug } = useParams();
	const [contest, setContest] = useState<ContestRead | undefined>(undefined);
	const [isFetchingContest, setIsFetchingContest] = useState(true);
	const [publicStandingsItems, setPublicStandingsItems] = useState<StandingsItem[]>([]);
	const [standingsItems, setStandingsItems] = useState<StandingsItem[]>([]);
	const [finalStandingsItems, setFinalStandingsItems] = useState<StandingsItem[]>([]);
	const [problemsAttempts, setProblemsAttempts] = useState<ProblemAttempt[]>([]);
	const [finalProblemsAttempts, setFinalProblemsAttempts] = useState<ProblemAttempt[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const localStorageIdforAnnouncedIndices = `${CONTESTS_NAMESPACE}/${contestSlug}/announced-indices`;
	const announcedIndicesRef = useRef<Set<number>>(new Set());

	useEffect(() => {
		const savedIndices = localStorage.getItem(localStorageIdforAnnouncedIndices);
		if (savedIndices) {
			announcedIndicesRef.current = new Set(JSON.parse(savedIndices));
		}
	}, []);

	const addIndexToAnnouncedIndices = (index: number) => {
		announcedIndicesRef.current.add(index);
		localStorage.setItem(localStorageIdforAnnouncedIndices, JSON.stringify(Array.from(announcedIndicesRef.current)));
	};

	useEffect(() => {
		const fetchContest = async () => {
			setIsFetchingContest(true);
			const contestData = await getContestData(contestSlug!);
			setContest(contestData);
			setIsFetchingContest(false);
		};
		if (!contest) fetchContest();
	}, []);

	useEffect(() => {
		if (!contest) return;
		fetchStandings(1);
	}, [contest]);

	const fetchStandings = async (page: number) => {
		setIsLoading(true);
		try {
			const [publicStandings, internalStandings] = await Promise.all([
				getPublicStandings(contest!.id!, page),
				getInternalStandings(contest!.id!, page),
			]);

			publicStandings.results.forEach((item: StandingsItem, idx: number) => {
				item.rank = idx + 1;
			});

			internalStandings.results.forEach((item: StandingsItem, idx: number) => {
				item.rank = idx + 1;
			});

			setPublicStandingsItems(publicStandings.results);
			setStandingsItems(getPublishedRows(publicStandings.results, internalStandings.results));
			setProblemsAttempts(publicStandings.problem_attempts);

			setFinalStandingsItems(internalStandings.results);
			setFinalProblemsAttempts(internalStandings.problem_attempts);
		} catch (error) {
			enqueueSnackbar('Error loading standings!', {
				autoHideDuration: 3000,
				variant: 'error',
			});
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const [highlightedRow, setHighlightedRow] = useState<number | null>(null);

	const containerRef = useRef<HTMLDivElement>(null);
	const rowRefs = useRef<Map<number, HTMLDivElement>>(new Map());

	const onResetPublishedRanks = () => {
		announcedIndicesRef.current.clear();
		setHighlightedRow(null);
		localStorage.removeItem(localStorageIdforAnnouncedIndices);
		fetchStandings(1);
	};

	const getPublishedRows = (currentItems: StandingsItem[], finalItems: StandingsItem[]) => {
		const announcedIndicesSorted = Array.from(announcedIndicesRef.current).sort((a, b) => a - b);
		const setOfIdsFromAnnouncedIndices = new Set(announcedIndicesSorted.map((idx) => finalItems[idx]?.id));

		const updatedStandings = currentItems.filter((item) => !setOfIdsFromAnnouncedIndices.has(item.id));

		announcedIndicesSorted.forEach((announcedIndex) => {
			updatedStandings.splice(announcedIndex, 0, finalItems[announcedIndex]);
		});

		const rankedData = updatedStandings.map((row, idx) => ({
			...row,
			rank: idx + 1,
		}));

		return [...rankedData];
	};

	const onPublishRank = (index: number) => {
		addIndexToAnnouncedIndices(index);

		// if (containerRef.current && rowRefs.current.has(finalItem.id)) {
		// 	rowRefs.current.get(finalItem.id)?.scrollIntoView({
		// 		behavior: 'smooth',
		// 		block: 'center',
		// 	});
		// }

		// setTimeout(() => {
		// }, 2000);

		setStandingsItems(getPublishedRows(publicStandingsItems, finalStandingsItems));
		setHighlightedRow(finalStandingsItems[index].id);
	};

	const handleLayoutAnimationStart = () => {};

	const handleLayoutAnimationComplete = () => {};

	if (isFetchingContest || !contest) return <LinearLoader />;

	// const contestNotStarted = moment(contest?.starts_at).isAfter(moment());
	// if (contestNotStarted) return <ContestNotStartedPage contest={contest} />;

	return (
		<Grid container direction={'column'} xs={12} justifyContent={'center'} sx={{ padding: '24px' }} spacing={2}>
			<Grid item>
				<ContestHeading
					name={contest?.title ?? ''}
					starts_at={moment(contest?.starts_at)}
					stops_at={moment(contest?.ends_at)}
				/>
			</Grid>
			<Grid item>
				<Typography color={'secondary'} variant={'body1'} align={'center'}>
					You are watching the private standings announcement page! Changes made here do not affect the public
					standings.
				</Typography>
			</Grid>
			{contest?.is_frozen && (
				<Grid item container direction={'column'}>
					<Typography color={'primary'} variant={'h5'} align={'center'}>
						The standings is currently frozen!
					</Typography>
				</Grid>
			)}
			{isLoading && (
				<Box sx={{ width: '100%', paddingX: '20px', marginTop: '20px' }}>
					<LinearProgress />
				</Box>
			)}
			<Grid item>
				<TableContainer ref={containerRef}>
					<Table>
						<TableHead sx={{ backgroundColor: '#f8f6f6' }}>
							<TableRow>
								<StyledRankCell align='center'>
									<strong className='rank-display'>#</strong>
									<Tooltip title='Reset to public standings' arrow>
										<IconButton className='reload-icon' onClick={onResetPublishedRanks}>
											<RestoreIcon color='primary' />
										</IconButton>
									</Tooltip>
								</StyledRankCell>
								<TableCell sx={{ minWidth: '300px' }}>
									<Grid container direction='row' justifyContent='space-between' alignItems='center'>
										<Grid item>
											<strong>Name</strong>
										</Grid>
									</Grid>
								</TableCell>
								<TableCell style={problemHeaderStyle} />
								{problemsAttempts.map((problem, index) => (
									<TableCell key={index} align={'center'} style={problemHeaderStyle}>
										<Link
											to={`/contests/${contestSlug}/problems/${problem.number_to_alpha}`}
											style={{ textDecoration: 'none', color: 'inherit' }}
										>
											<div>
												<strong>{problem.number_to_alpha}</strong>
											</div>
										</Link>
										<Divider />
										<div style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>
											<strong style={{ display: 'inline-block', maxWidth: '100%' }}>
												{`${problem.total_accepted} / ${problem.total_tries}`}
											</strong>
										</div>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{standingsItems.map((row, index) => {
								return (
									<AnnounceStandingsRow
										key={row.id}
										rank={index + 1}
										data={row}
										onPublishRankClick={() => {
											onPublishRank(index);
										}}
										motionProps={{
											key: row.id,
											ref: (el: HTMLDivElement | null) => {
												if (el) rowRefs.current.set(row.id, el);
											},
											layout: true,
											initial: { opacity: 0 },
											animate: { opacity: 1 },
											exit: { opacity: 0 },
											transition: {
												type: 'spring',
												stiffness: 300,
												damping: 100,
											},
											style: {
												backgroundColor:
													row.id === highlightedRow
														? '#FFD700'
														: announcedIndicesRef.current.has(index)
														? '#cce4ff'
														: '#ffffff', // Golden color for highlight
												position: 'relative',
												zIndex: row.id === highlightedRow ? 1 : 0,
											},
											// onClick: () => moveToSecond(row.id),
											// onLayoutAnimationStart: handleLayoutAnimationStart,
											// onLayoutAnimationComplete: handleLayoutAnimationComplete,
										}}
									/>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export default ContestStandingsAnnouncement;
